@import 'tailwindcss/utilities';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style-type: disc;
  /*padding-left: 1.25rem;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.radix-themes:where([data-is-root-theme='true']) {
  min-height: unset !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

#category_label > button {
  width: 100%;
}

.category-chip {
  width: 100%;
}

.card-iconBtn {
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--accent-10) !important;
  :hover {
    cursor: pointer;
  }
}

.cardBtn {
  background-color: var(--accent-3) !important;
  color: var(--accent-10) !important;
}

.cardLi:hover {
  border: 1px solid var(--accent-9);
  /*background-color: var(--accent-9);*/
}

#import-section button {
  border-radius: 0.3rem;
}

#import-section .import-btn {
  background-color:  theme("colors.primary");
  color: theme("colors.primary2");
}

#import-section .export-btn {
  background-color:  theme("colors.primary4");
  color: #0b161a;
}

.my-dialog {
  max-width: 450px;
  /*border-radius: 0.5rem;*/
  padding: 2.25rem;
}

.my-dialog h1 {
  font-size: 1.4rem;
}

.my-input {
  /*border-radius: 0.25rem;*/
  width: 100%;
}

.my-input2 {
  /*border-radius: 0.25rem;*/
  width: 50%;
}

.my-btn {
  padding: 1.25rem 1rem !important;
}

.my-btn:hover {
  cursor: pointer
}

/*.my-secondary-btn {*/
/*  background-color: theme("colors.gray200");*/
/*  color: theme("colors.gray800");*/
/*}*/

/*.my-secondary-btn:hover {*/
/*  background-color: theme("colors.gray300");*/
/*}*/

.rt-TabNavLinkInner .my-navlink {
  padding: 0.75rem !important;
}

.sticky-tags-container {
  position: sticky;
  bottom: 0;
  max-height: 100px; /* Adjust this value according to your needs */
  overflow-y: auto; /* This will add a vertical scrollbar when the content exceeds the max-height */
}

.sticky-tags {
  overflow-y: auto; /* This will add a vertical scrollbar when the content exceeds the max-height */
}

.sticky-dummy-container {
  min-height: calc(100vh - 133px);
}

.sticky-dummy-sub-container {
  min-height: calc(100vh - 233px);
}

#contact-us-form label {
  padding-bottom: 0;
  margin: 1.5rem 0 0.2rem 0.2rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: #1f2937;
}

#contact-us-form input,
#contact-us-form textarea {
  width: 100%;
  padding: 0.5rem 0.8rem;
  border: #dddddd 1px solid;
  border-radius: 0.4rem;
}

#contact-us-form input::placeholder,
#contact-us-form textarea::placeholder {
  font-style: italic;
  font-size: 0.85rem;
  color: rgb(201, 201, 201);
}